<template>
  <div class="head">
    <div class="headers">
      <div class="core">
        <div class="logo">
          <a :href="url">
            <img :src="agency_logo" alt="">
            <!-- <img src="https://www.zhulong.com/img/pubimg/logo_white.png" alt=""> -->
          </a>
        </div>
        <!-- 列表 -->
        <ul class="list">
          <li @mouseover="mouseovered" @mouseout="mouseouted">
            <p class="change_select" style="min-width:124px">
              {{ $route.query.title || className }}
              <img style="width:17px;marginLeft:2px;" src="@/assets/img/down_red.png" alt="">
              <!-- <span class="down_icon"><i style="color:#fff" class="el-icon-arrow-down"></i></span> -->
            </p>

            <div v-show="isClassBox" class="hide-box"><!-- v-show="isClassBox" -->
              <i class="biad_sj_i"></i>
              <!-- <i class="el-icon-caret-bottom box-up"></i> -->
              <!-- <el-scrollbar style="height:280px;width:310px;overflow-x:hidden;"> -->
              <p style="paddingLeft:12px;" class="go_index"><a :href="url" :class="curindexs===-1?'':'go_url'">网站首页</a></p>
              <div v-for="(i2,t2) in one.nav" :key="t2">
                <p><a style="color:#000;paddingLeft:6px;">{{ i2.category_name }}</a></p>
                <div class="zifenlei">
                  <!-- <span
                    v-for="(item,index) in i2.children"
                    :key="index"
                    @click="tagsFun(item)"
                  >{{ item.category_name }}</span>
                </div> -->
                  <!-- 判断路由中的title和item的category_name相同加span_active类名 -->
                  <span
                    v-for="(item,index) in i2.children"
                    :key="index"
                    :class="$route.query.title==item.category_name?'span_active':''"
                    @click="tagsFun(item)"
                  >{{ item.category_name }}</span>
                </div>
              </div>
              <!-- </el-scrollbar> -->
            </div>

          </li>
          <li
            v-for="(i,t) in list"
            :key="t"
            :class="t == curindexs ? 'active':''"
            style="margin:0 12px;"
            @mouseover="showCode(i)"
            @mouseout="hideCode(i)"
          >
            <p>
              <a :href="i.name==='APP'?'javascript:;':i.url" :target="i.name==='APP'?'_self':'_blank'" class="header_title"><span>{{ i.name }}</span> <span class="line">
              </span></a>
              <i class="el-icon-caret-bottom icon-position"></i>
            </p>
            <!-- <div v-show="show_code" v-if="i.name==='APP'" class="code_box">
              <i class="biad_sj_i"></i>
              <ul>
                <li v-for="(item,index) in app_info" :key="index">
                  <p class="info_title">{{ item.name }}</p>
                  <img :src="item.qr_code" alt="">
                  <p class="info">{{ item.content }}</p>
                </li>
              </ul>
            </div> -->
          </li>
          <li v-if="more.name" style="min-width:80px">
            <p>
              {{ more.name }}
              <img style="width:11px;" src="@/assets/img/select.png" alt="">
              <!-- <i style="color:#000" class="el-icon-arrow-down"></i> -->
            </p>
            <div class="zhul_arrowmore">
              <i class="biad_sj_i"></i>
              <!-- <i class="el-icon-caret-bottom box-up"></i> -->
              <ul>
                <li
                  v-for="(i,t) in more.list"
                  :key="t"
                  style="margin:0;"
                  @mouseover="showCode(i)"
                  @mouseout="hideCode(i)"
                >
                  <a :href="i.name==='APP'?'javascript:;':i.url" :target="i.name==='APP'?'_self':'_blank'">{{ i.name }}</a>
                  <!-- app二维码 -->
                  <div v-show="show_code" v-if="i.name==='APP'" class="code_box"><!--  v-show="show_code" -->
                    <!-- <i class="el-icon-caret-bottom box-up"></i> -->
                    <i class="biad_sj_i" style="left:-6px;top:160px;transform:rotate(-90deg)"></i>
                    <ul>
                      <li v-for="(item,index) in app_info" :key="index">
                        <p class="info_title">{{ item.name }}</p>
                        <img :src="item.qr_code" alt="">
                        <p class="info">{{ item.content }}</p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <!-- 搜索框 -->
        <div class="search-ipt">
          <input
            v-model="value"
            type="text"
            class="inputs"
            :placeholder="showPlaceholder ? '请输入关键词':''"
            @keydown.enter="search_hotWord"
            @focus="searchWords()"
            @blur="cancelSearch()"
          >
          <p v-show="!showPlaceholder" class="hot-words">
            <span v-for="(i,t) in hotWords" v-show="t <= 1" :key="t" @click="getHot(i)">{{ i }}</span>
          </p>
          <!-- <a href="javascript:;" target="_blank" @click="search_hotWord"><i class="search-icon"></i></a> -->
          <span class="search-btn" @click="search_hotWord">搜索</span>
        </div>
        <!-- 个人详情 -->
        <div class="info">
          <div v-show="uid != undefined" class="info-user">
            <a>
              <img class="avator" :src="avator" alt="">
              <i style="font-style:normal;cursor:pointer"> {{ username.length>5?username.slice(0,5)+'...':username }} </i>
            </a>
            <!-- <i class="el-icon-arrow-down" style="margin-right:10px;color:#000;"></i> -->
            <img style="width:11px;" src="@/assets/img/select.png" alt="">
            <span style="color:#ccc;marginLeft:8px">|</span>
            <div class="hide-box2">
              <i class="biad_sj_i"></i>
              <!-- <i class="el-icon-caret-bottom box-up"></i> -->
              <span v-for="(i,t) in uses_more" :key="t">
                <a :href="i.url" target="_blank">{{ i.name }}</a>
              </span>
              <span>
                <a @click="onOutLogin">退出</a>
                <!-- <span style="color:#000" @click="onOutLogin">
                                  退出
                                </span> -->
              </span>
            </div>
          </div>
          <div v-show="!uid" class="info-user">
            <!-- <p @click="login()">登录</p> -->
            <p>
              <span style="cursor: pointer;color:#333;marginRight:12px;" @click="onLogin">注册</span>
              <span style="color:#999;marginRight:12px;">|</span>
              <span style="cursor: pointer;color:#333" @click="onLogin">登录</span>
              <span style="color:#999;marginLeft:12px;">|</span>
            </p>
          </div>
          <div class="info-text">
            <a v-for="(item,index) in message" :key="index" :href="item.url" target="_blank">
              {{ item.name }}
            </a>
            <!-- <i v-show="infoTextNum != 0">{{ infoTextNum }}</i> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 登录 -->
    <!-- <login v-show="gologin" @close="close()"></login> -->
    <!-- <div v-show="uid == undefined" class="motai"></div> -->
  </div>
</template>

<script>
import Cookies from 'js-cookie'
// import login from '@/components/login_h'
import { getPublic } from '@/api/public.js'
import { signOut, loginurl } from '@/api/login.js'
export default {
  components: {
    // login
  },
  props: {
    curindexs: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      show_code: false, // app二维码
      app_info: [],
      url: '',
      avator: '', // 用户头像
      agency_logo: '', // 企业logo
      indexs: -1,
      className: '网站首页',
      classId: '',
      isClassBox: false,
      value: '',
      uid: '',
      username: '',
      header_img: '',
      infoTextNum: 1,
      list: [],
      one: {},
      more_name: '',
      more: {},
      uses_more: [],
      message: [],
      tags: [],
      hotWords: [],
      showPlaceholder: false,
      gologin: false,
      timer: null,
      searchWord: {},
      time_num: 1,
      search_url: '',
      statis: {}
    }
  },
  created() {
    this.getPageBaseUrl()
    this.uid = Cookies.get('uid')
    // console.log(this.uid, 'this.uid')
    this.username = Cookies.get('username') || ''
    if (this.uid) {
      this.getAvator(this.uid)
    }
    // console.log('=============>' + this.username)
    if (this.uid === undefined) {
      this.gologin = true
    }
    this.getData()
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    // 获取当前网页的协议+域名
    getPageBaseUrl() {
      let baseURL = ''
      if (!window.location.origin) { // 兼容IE，IE11版本下location.origin为undefined
        window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
      } else {
        baseURL = window.location.origin
      }
      this.url = baseURL
    },
    getData() {
      // console.log(1111111)
      const info = {
        type: 1
      }
      getPublic(info).then((res) => {
        if (res.errNo === 0) {
          // console.log(res.result)
          this.list = res.result.top.top
          this.one = res.result.top.one
          // this.className = res.result.top.one.name.name
          this.more = res.result.top.more
          // this.more_name = res.result.top.one.name.name
          this.uses_more = res.result.top.uses_more
          this.message = res.result.top.message
          // console.log(this.message, 'this.message')
          this.searchWord = res.result.top.search
          this.hotWords = res.result.top.search.one_page
          if (res.result.top.agency) {
            this.agency_logo = res.result.top.agency.thumb
          }
          this.app_info = res.result.top.app_info
          this.search_url = res.result.top.sou[0].url
          // 三方统计
          this.statis = res.result.top.statis
          // var bd_script = document.createElement('script')
          // bd_script.type = 'text/javascript'
          // bd_script.innerHTML = this.statis.baidu
          // // console.log(this.statis, 'this.statis')
          // document.body.appendChild(bd_script)
          var cc_script = document.createElement('script')
          cc_script.type = 'text/javascript'
          cc_script.innerHTML = this.statis.circle
          document.body.appendChild(cc_script)
          var cz_script = document.createElement('script')
          cz_script.type = 'text/javascript'
          cz_script.src = 'https://s4.cnzz.com/z_stat.php?id=5078536&web_id=5078536'
          cz_script.innerHTML = this.statis.cnzz
          document.body.appendChild(cz_script)
        }
      }).then(() => {
        this.timer = setInterval(() => {
          this.time_num++
          if (this.time_num % 3 === 1) {
            this.hotWords = this.searchWord.one_page
          } else if (this.time_num % 3 === 2) {
            this.hotWords = this.searchWord.two_page
          } else {
            this.hotWords = this.searchWord.three_page
          }
        }, 10000)
        // cookie中获取展示分类
        // if (Cookies.get('className')) {
        //   this.classId = Cookies.get('classId')
        //   this.className = Cookies.get('className')
        //   this.$emit('onClassEvent', this.classId)
        // } else {
        //   this.isClassBox = true
        // }
      })
      // .then(res => {
      //   if (Cookies.get('className')) {
      //     this.classId = Cookies.get('classId')
      //     this.className = Cookies.get('className')
      //     this.$emit('onClassEvent', this.classId)
      //   } else {
      //     this.isClassBox = true
      //     // this.className = this.one.nav[0].children[0].category_name
      //   }
      // })
    },
    getAvator(uid) {
      const domain = 'https://newoss.zhulong.com/tfs/'
      let num = uid
      const n = 9
      let len = num.toString().length
      while (len < n) {
        num = '0' + num
        len++
      }
      uid = num
      const dir1 = uid.substr(0, 3)
      const dir2 = uid.substr(3, 2)
      const dir3 = uid.substr(5, 2)
      this.avator = domain + dir1 + '/' + dir2 + '/' + dir3 + '/' + uid.substr(-2) + '_avatar_big.jpg'
    },
    mouseovered() {
      this.isClassBox = true
    },
    mouseouted() {
      this.isClassBox = false
      // if (Cookies.get('className')) {
      //   this.isClassBox = false
      // } else {
      //   this.isClassBox = true
      // }
    },
    showCode(item) {
      // console.log(item)
      if (item.name === 'APP') {
        this.show_code = true
      }
    },
    hideCode(item) {
      // console.log(item)
      if (item.name === 'APP') {
        this.show_code = false
      }
    },
    searchFun() {
      // console.log(this.value)
    },
    search_hotWord() {
      // console.log('q12434')
      window.open(this.search_url + '?q=' + this.value)
    },
    searchWords() {
      this.showPlaceholder = true
    },
    cancelSearch() {
      this.showPlaceholder = false
    },
    getHot(i) {
      window.open(this.search_url + '?q=' + i)
    },
    login() {
      this.gologin = true
    },
    close(val) {
      this.gologin = val
    },
    tagsFun(ele) {
      // console.log(ele)
      // window.open('/bbs/classify?fid=' + ele.id)
      window.open(ele.url)
      // this.className = ele.category_name
      // this.classId = ele.id
      // Cookies.set('className', ele.category_name, { expires: 7 })
      // Cookies.set('classId', ele.id, { expires: 7 })
      // this.isClassBox = false
      // this.$emit('onClassEvent', this.classId)
    },
    onLogin() {
      const url = window.location.href
      loginurl({ redirect: url }).then((res) => {
        if (res.errNo === 0) {
          window.location.href = res.result.url
        }
      })
      // if (process.env.NODE_ENV === 'development') {
      //   window.open(`http://testopenwww.zhulong.com/ucenter/login?redirect=${url}`, '_self')
      // } else {
      //   window.open(`http://openwww.zhulong.com/ucenter/login?redirect=${url}`, '_self')
      // }
    },
    onOutLogin() {
      var info = {
        agency_id: '',
        app_id: '1',
        uuid: this.uid,
        token: ''
      }
      signOut(info).then(res => {
        if (res.errNo === 0) {
          this.$message.success('退出成功')
          Cookies.remove('uid')
          Cookies.remove('username')
          this.onLogin()
        }
      })
    }
  }
}
</script>

<style scoped lang="less">

    .head {
        position: sticky;
        top: 0;
        z-index: 999;
        // box-shadow: 0 2px 7px 5px rgba(0,0,0,0.04);
    }

    .headers {
        position: fixed;
        top:0;
        width: 100%;
        height: 60px;
        margin-top: 0;
        background: #fff;
        border-bottom:1px solid #ECECEC;
        .core {
            width: 1200px;
            height: 100%;
            margin: 0 auto;
            line-height: 60px;
            display: flex;
            justify-content: space-between;
            .logo {
                width: 137px;
                height: 37px;
                transform: scale(0.79);
                margin-left: -15px;
                margin-right: 12px;
                img{
                  height:36px;
                }
            }
        }
    }

    .list {
        display: flex;
        min-width: 559px;
        font-weight:550;
        align-items: center;
        // font-size:16px;
        &>li {
            display: flex;
            align-items: center;
            margin-left: 12px;
            position: relative;

            p {
              min-width:32px;
              font-size:16px;
                padding: 0 4px;
                text-align: center;
                color: #333;
                position: relative;
                cursor: pointer;
                a{
                  color: #333;
                }
            }
        }

        // li:not(1) {
        //     p {
        //         display: flex;
        //        flex-direction: column;
        //        align-items: center;
        //     }
        // }
        li:nth-child(1) {
            p {
                margin-left: -10px;
                margin-right: 10px;
            }
        }
        li .header_title{
          display: flex;
          flex-direction: column;
          // justify-content: space-between;
          align-items: center;
          text-align: center;
          position: relative;
          // margin-top:4px;
        }
        li .line{
          position: absolute;
          // left:6px;
          height: 3px;
          top: 40px;
          width:20px;
          padding-bottom: 4px;
          background: transparent;
          border-radius: 2px;
          margin-top:4px;
        }
        li:hover .line{
          background: #ee2e2e;
        }
        li:hover p{
         color:#666;
        }
        // li .header_title{
        //   padding-bottom: 4px;
        //   border-bottom:3px solid transparent;
        // }
        // li:hover .header_title{
        //   padding-bottom: 4px;
        //   border-bottom:3px solid #ee2e2e;
        // }

        li:nth-last-child(1):hover {
            background: #fff;
        }

        li:nth-child(1):hover {
            background: #fff;
            // .hide-box{
            //   display: block;
            //   padding: 5px 20px 15px;
            //   line-height: 30px;
            //   min-width: 320px;
            //   div{
            //      p{
            //       cursor: auto;
            //       color:#000;
            //       font-weight: bold;
            //       text-align: left;
            //       margin-top: 5px;
            //     }
            //     span{
            //       color: #000;
            //       border-radius: 10px;
            //       border: 1px solid #969494;
            //       padding: 2px 8px;
            //       cursor: pointer;
            //       margin-right: 10px;
            //     }
            //     span:hover{
            //       color:#ee2e2e;
            //       border-color: #ee2e2e;
            //     }
            //   }
            // }
        }

        .zifenlei {
            display: flex;
            flex-wrap: wrap;
            span{
              border-color:#ededed;
              font-weight:400 !important;
            }
        }

        .hide-box {
          position:absolute;
          top:36px;
            display: block;
            padding: 16px 10px 15px;
            line-height: 30px;
            width: 480px;
            // max-width: 320px;
            // max-height: 300px;
            // overflow-x: hidden;
            border-radius: 5px;
            div {
                p {
                    cursor: auto;
                    color: #000;
                    font-weight:550;
                    text-align: left;
                    margin-top: 5px;
                }

                span {
                    display: inline-block;
                    line-height: 16px;
                    color: #444;
                    border-radius: 999px;
                    border: 1px solid #555;
                    padding: 3px 8px;
                    cursor: pointer;
                    margin-right: 10px;
                    margin-bottom: 8px;
                }
                .span_active{
                  background: #ee2e2e;
                  border: 1px solid #ee2e2e;
                  color:#fff;
                }
                span:hover {
                    background: #ee2e2e;
                    border: 1px solid #ee2e2e;
                    color:#fff;
                }
            }
        }
    }

    .search-ipt {
        width: 200px;
        height: 40px;
        margin-left: 45px;
        position: relative;
        top:6px;
        input {
            width: 185px;
            transition: all 0.5s;
            height: 27px;
            position: absolute;
            right: 0;
            top: 6px;
            border:1px solid #ccc;
            outline: none;
            padding:17px 10px;
            box-sizing: border-box;
            border-radius: 20px;
            line-height: 1;
        }

        input:focus {
            width: 250px;
            transition: all 0.5s;
        }

        .hot-words {
            min-width: 20px;
            height: 20px;
            position: absolute;
            top: 14px;
            right: 50px;
            display: flex;
            align-items: center;

            span {
                font-size: 12px;
                height: 20px;
                line-height: 20px;
                background: #f5f5f5;
                color: #afafaf;
                border-radius: 10px;
                padding: 0px 8px;
                margin-left: 5px;
                cursor: pointer;
            }

            span:hover {
                color: #fff;
                background: #ee2e2e;
            }
        }

        .search-btn {
            display: inline-block;
            font-size: 12px;
            background: #ee2e2e;
            color:#fff;
            background-position: 45px 36px;
            // width: 30px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 14px;
            position: absolute;
            top: 10px;
            right:5px;
            padding:0 10px;
            cursor: pointer;
        }
        .search-icon {
            display: inline-block;
            background: url('https://www.zhulong.com/img/header/top-sprite.png');
            background-position: 45px 36px;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 5px;
            right: -2px;
        }

        .search-icon:hover {
            background-position: 45px 36px;
        }
    }

    .info {
        color: #fff;
        height: 40px;
        display: flex;
        line-height: 40px;
        margin-left: 5px;

        div {
            margin: 0 6px;
            font-size: 12px;
        }

        .info-user {
          font-size :16px;
          font-weight:550;
            position: relative;
            width: 167px;
            text-align: right;
            top:10px;
            a {
                color: #333;
            }

            p {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;
            }

            .avator {
                display: inline-block;
                position: relative;
                top: 5px;
                width: 30px;
                cursor: pointer;
                height: 30px;
                border-radius: 50%;
                margin-top:-14px;
                margin-right:4px;
                // vertical-align: center;
                // background: url('../assets/img/user.png') no-repeat;
                // background-size: 100% 100%;
            }
        }

        div:nth-child(1):hover {
            .hide-box2 {
                display: block;
                text-align: center;
                position: absolute;
                left: 50px;
                z-index: 668;

                span {
                    display: block;
                    width: 100px;
                    height: 30px;
                    margin-top: 5px;
                    line-height: 30px;

                    a {
                        color: #666;
                        display: inline-block;
                        width: 100%;
                    }
                }

                span a:hover {
                    background: #f5f5f5;
                    color: #ee2e2e;
                }

                span:last-child {
                    border-top: 1px solid #ddd;
                }

                //  span:last-child:hover{
                //    border-top:none;
                //  }
            }
        }

        .info-text {
            font-weight:550;
            font-size:16px;
            color:#333;
            position: relative;
            top:10px;
            a {
                color: #000;
            }

            i {
                display: inline-block;
                width: 12px;
                height: 12px;
                font-style: normal;
                text-align: center;
                line-height: 12px;
                font-size: 12px;
                border-radius: 100%;
                background: #ee2e2e;
                position: absolute;
                right: -12px;
                top: 5px;
                color: #fff;
            }
        }
    }

    .icon-position {
        position: absolute;
        left: 38%;
        top: 34px;
        z-index: 10;
        color: #ee2e2e;
        transform: scale(2.5, 1.8);
        display: none;
    }

    .hide-box, .hide-box2 {
        min-width: 100px;
        min-height: 10px;
        background: #fff;
        position: absolute;
        top: 35px;
        left: 0;
        z-index: 11;
        box-shadow: 0 2px 7px 5px rgba(0,0,0,0.1);
        display: none;
    }

    .box-up {
        color: #fff;
        transform: rotate(180deg);
        position: absolute;
        left: 40px;
        top: -7px;
    }

    .active {
        .header_title{
        .line{
            background: #ee2e2e;
          }
      }
    }

    .zhul_arrowmore {
        display: none;
        background: #fff;
        /* border: 1px solid #989898; */
        position: absolute;
        left: -25px;
        top: 42px;
        width: 100px;
        z-index: 9999;
        background: #fff;
        box-shadow: 0 2px 7px 5px rgba(0,0,0,0.1);
        padding: 5px 0;

        a {
            display: block;
            float: left;
            height: 32px;
            line-height: 32px;
            text-align: center;
            width: 100%;
            color: #444;
            font-size: 12px !important;

            &:hover {
                background: #E5E5E5;
            }
        }
    }
    .list{
      .change_select{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor:pointer;
        margin-top: 5px;
        float:left;
        border:1px solid #d4d4d4;
        margin-top: 0px;
        height: 36px;
        line-height: 36px;
        padding:0 10px;
        border-radius: 18px;
      }
      .down_icon{
        padding: 0 2px;
        border-radius: 50%;
        background: #ee2e2e;
        color:#ffff;
        font-size:12px;
        margin-left: 4px;
      }

    }
    .list li:nth-last-child(1):hover .zhul_arrowmore {
        display: block;
    }
    /deep/.el-scrollbar__wrap {
      // overflow: scroll;
      width: 105%;
      height: 106%;
     }
    .code_box{
        background: #fff;
        position: absolute;
        left: 115px;
        top: 18px;
        background: #FFFFFF;
        box-shadow: 0 2px 7px 5px rgba(0,0,0,0.1);
        border-radius: 4px;
        border-radius: 4px;
        z-index: 5;
        padding:0 5px;
        ul{
          display: flex;
          padding-bottom:20px;
          li{
            margin: 0 5px;
            display:flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .info_title{
              height:50px;
              font-size: 16px;
              color: #333333;
              letter-spacing: 0;
              text-align:center;
            }
            .info{
              height:22px;
              line-height:1.2;
              text-align: center;
              padding:0;
              font-size: 14px;
              color: #666666;
              letter-spacing: 0;
              margin:0;
              margin-top:5px;
            }
            img{
              width:140px;
              height:140px;
              border:1px solid #f3f3f3;
            }
          }
        }
    }
    .go_index{
      text-align: left !important;
      .go_url{
        background:#ee2e2e;
        color:#fff !important;
      }
    }
    .go_index a{
      font-size:14px !important;
      display: inline-block;
      line-height: 16px;
      color: #333;
      border-radius: 999px;
      border: 1px solid #ee2e2e;
      padding: 2px 8px;
      cursor: pointer;
      margin-right: 10px;
      margin-bottom: 8px;
      font-weight: 400;
      background:#ee2e2e;
      color:#fff !important;
      padding: 3px 8px;
    }
    .biad_sj_i {
        position: absolute;
        display: inline-block;
        width: 9px;
        height: 4px;
        background: url(../assets/img/talk_header_j.png) no-repeat;
        z-index: 9999;
        top: -4px;
        left: 48px;
    }
</style>

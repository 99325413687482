<template>
  <div>
    <div class="zhul_footer clientHidden">
      <div class="zhul_bot_footer">
        <p>
          <a v-for="(item,index) in footer[0]" :key="index" :href="item.url" style="color: #666;margin:0 10px 10px 0;">
            <span @click="toNewPage(item.id)">{{ item.name }}</span>
          </a>
        </p>
        <p>
          <a v-for="(item,index) in footer[1]" :key="index" :href="item.url" style="color: #666">
            <img style="vertical-align:middle;" :src="item.thumb" alt="">
            <span>{{ item.name }}</span>
          </a>
        </p>
        <p><span v-for="(item,index) in footer[2]" :key="index" style="margin-right: 10px">{{ item.name }}</span></p>
        <div class="trust-box">

          <a v-for="(item,index) in footer[3]" :key="index" href="javascript:;" style="cursor:default">
            <img :src="item.thumb" :alt="item.name">
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getPublic } from '@/api/public.js'
export default {
  data() {
    return {
      footer: []
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      var params = {
        type: 2
      }
      getPublic(params).then((res) => {
        if (res.errNo === 0) {
          // console.log(res.result)
          this.footer = res.result.bottom
        }
      })
    },
    toNewPage(item) {
      window.open(`https://www.zhulong.com/bbs/footernew?menu_id=${item}`)
    }
  }
}

</script>
<style scoped lang="less">
.zhul_footer {
    width: 1200px;
    margin: 10px auto;
}
  .zhul_bot_footer {
    width: 1200px;
    text-align: center;
    padding: 20px 0;
    line-height: 25px;
    color: #666;
    font-size: 14px;
    &>p{
      .zhul_botmenu_link {
          padding: 0 15px;
          border-right: solid 1px #cad5d7;
          color: #666;
          text-decoration: none;
          font-size: 14px;
          font-family: "微软雅黑",Verdana, Arial, Helvetica, sans-serif;
      }
    }
    .trust-box {
        padding-top: 20px;
        text-align: center;
    }
    .trust-box {
        padding-top: 20px;
        text-align: center;
        img {
          width: 114px;
          margin-right: 20px;
      }
    }
    .zhul_botmenu_unbor {
        padding: 0 15px;
        border-right: none;
        color: #666;
        text-decoration: none;
        font-family: "微软雅黑",Verdana, Arial, Helvetica, sans-serif;
        font-size: 14px;
    }
}
</style>

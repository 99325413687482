import request from '../utils/agreement'
import ucenter_request from '@/utils/ucenter_request.js'

export function getAgreement(params) {
  return request({
    url: 'pay/order/getPayProtocol',
    method: 'post',
    params
  })
}

// 获取协议信息
export function getAgreementInfo(params) {
  return ucenter_request({
    url: 'personal/Agreement/getAgreementInfo',
    method: 'post',
    params
  })
}

// 确定协议
export function confirmAgreement(params) {
  return ucenter_request({
    url: 'personal/Agreement/confirmAgreement',
    method: 'post',
    params
  })
}
